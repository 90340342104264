import React, {useEffect, useMemo, useRef, useState} from 'react';
import CommonContainer from "@/components/common/Container";
import styled from "styled-components";
import Text from "@/components/common/Text";
import Image from "@/components/common/Image";
import MockupImage from '@/assets/images/dunes.jpg'
import {
	getCssSizeValue,
	getHtmlFromRedactorField,
	getMapKey,
	getPaddingsClassFromData,
	isStringEmpty
} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Donor, {DEFAULT_DONOR} from "@/components/DonorStories/Donor";
import HeaderDescription from "@/components/common/Header&Description";
import {useBranch} from "@/hooks/useBranch";
import useScrollContext from "@/hooks/useScrollContext";
import dynamic from "next/dynamic";
import {useIsMobile} from "@/hooks/useDevice";
import useGlobals from "@/hooks/useGlobals";
import {GLOBAL_STATE} from "@/constants";

const LinkButton = dynamic(import('@/components/common/Buttons/LinkButton'), { ssr: false })

const Container = styled(CommonContainer)`
	display: grid;
	grid-template-columns: 1fr;
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const Side = styled.div`
	position: relative;
	
	.image{
		aspect-ratio: 948/463;
	}
	
	&.left{
		order: 2;
	}
	&.right{
        order: 1;
		background-color: ${({ theme }) => theme.colors.antiflashWhite};

	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
        &.left{
            order: 1;
        }
        &.right{
            order: 2;
        }
    }
`

const Info = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: ${getCssSizeValue(26, BREAKPOINTS.MOBILE)};
    padding: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)} var(--paddingX) 0;

    blockquote {
        --m-left: ${getCssSizeValue(26, BREAKPOINTS.MOBILE)};
		--m-y: 16px;
        position: relative;
        margin: var(--m-y) 0 var(--m-y) var(--m-left);

        &:before {
            content: '';
            position: absolute;
            left: calc(var(--m-left) * -1);
            width: 1px;
            height: 100%;
            background-color: black;
        }
    }

    .story {
        grid-column: span 8;
    }

    .button {
        margin-top: auto;
        grid-column: span 4;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        blockquote {
            --m-left: ${getCssSizeValue(36, BREAKPOINTS.TABLET)};
            --m-y: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        }
        padding: ${getCssSizeValue(36, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        blockquote {
            --m-left: ${getCssSizeValue(36, BREAKPOINTS.DESKTOP)};
            --m-y: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
        }
        row-gap: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(48, BREAKPOINTS.DESKTOP)} var(--grid-gap) ${getCssSizeValue(48, BREAKPOINTS.DESKTOP)} var(--grid-margin-l);
    }
`

const Summary = styled.div`
	justify-content: center;
	padding: var(--paddingX);
	padding-top: ${getCssSizeValue(46, BREAKPOINTS.MOBILE)};
	padding-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
	row-gap: ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
	
	width: 100%;

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
        padding: ${getCssSizeValue(52, BREAKPOINTS.TABLET)} ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
        aspect-ratio: 948/463;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		padding: 0 var(--grid-margin-r) 0 ${getCssSizeValue(63, BREAKPOINTS.DESKTOP)};
    }
`

const DonorsList = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	overflow: auto;
	
    //disabled scrollbar
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
	
	
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
        flex-direction: column;
    }
	
	
`
function useGetCurrentStateData() {
	const allStates = useGlobals(state => state.states)
	const {selectedOption} = useBranch();
	return allStates.find(state => (state?.handle || '') === selectedOption)
}
const DonorStories = ({ data }) => {
	const id = data?.id || ''
	const headerDescription = data?.headerDescription || { header: '', description: ''}
	const paddings = getPaddingsClassFromData(data);
	const ImageRef = useRef();
	const buttonData = data?.button || {}
	const buttonExist = !isStringEmpty( buttonData?.id || '')
	const { selectedOption } = useBranch()
	//filter donors by current state context
	const state = useGetCurrentStateData()
	const shouldDisplayGlobalVideo = state?.shouldDisplayGlobalDonorStories || false;
	const stateToFilterBy = shouldDisplayGlobalVideo ? GLOBAL_STATE : selectedOption;
	const donors = useMemo(() => {
		const dnrs = data?.donors || [DEFAULT_DONOR]
		if(dnrs.length <= 0) return []
		return dnrs.filter((donor) => donor?.state?.handle?.toLowerCase() === stateToFilterBy)
	}, [data, selectedOption])
	const [currentDonor, setCurrentDonor] = useState(donors[0])
	const story = getHtmlFromRedactorField(currentDonor?.story || DEFAULT_DONOR.story)
	const scroll = useScrollContext()
	const isMobile = useIsMobile()

	useEffect(() => {
		// console.log(currentDonor);
		scroll.refresh() //refreshing scroll trigger bcoz content changes
	},[currentDonor])

	useEffect(() => {
		//update current donor on whole list change
		setCurrentDonor(donors[0])
	},[donors])

	// useScaleFadeIn({
	// 	el: ImageRef,
	// 	onInView: true
	// })

	return (
		<Container key={id} type="full" className={paddings}>
			<Side className="left column">
				<Image
					ref={ImageRef}
					src={currentDonor?.image?.url || MockupImage}
					alt={currentDonor?.image?.alt || DEFAULT_DONOR.image.alt}
					placeholder={currentDonor?.image?.blurUpThumb || 'empty'}
				/>
				<Info>
					<Text
						as="div"
						dangerouslySetInnerHTML={story}
						className="col-desktop-8 story typo-strawford typo-strawford-11"
					/>
					{buttonExist && (
						<LinkButton data={buttonData} />
					)}
				</Info>
			</Side>
			<Side className="right column">
				<Summary className="column summary">
					<HeaderDescription data={headerDescription} noDescription={isMobile} />
				</Summary>
				<DonorsList className="">
					{donors.map((donor, index) =>
						<Donor
							key={getMapKey(donor, index)}
							data={donor}
							active={currentDonor?.id === donor.id}
							onClick={setCurrentDonor}
						/>
					)}
				</DonorsList>
			</Side>
		</Container>
	);
};

export default DonorStories;
