import React, {memo, useRef} from 'react';
import Text from "@/components/common/Text";
import styled from "styled-components";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {gsap, useGSAP} from "@/utils/gsap";
import {useIsDesktop, useIsMobile} from "@/hooks/useDevice";

const Wrapper = styled.div`
	--translate: -100;
	display: flex;
	justify-content: center;
	position: relative;
	padding: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)} ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    grid-gap: var(--grid-gap);
	cursor: pointer;
	overflow: hidden;
	align-items: center;
	row-gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
	border: solid 1px black;
	width: 100%;
	min-width: fit-content;
	
	&:not(&:first-of-type){
		border-left: none;
	}
	
	p{
		height: fit-content;
	}
    &:before{
        content: '';
        inset: 0;
		top: -1px;
		bottom: -1px;
        position: absolute;
		transform: translateX(calc(var(--translate) * 1%));
    }
	
	&.active{
		p {
            transition: color var(--rgr-duration) var(--ease);
		}
        &:before{
            background-color: ${({ theme }) => theme.colors.periwinkle};
        }
	}
	
	.age{
		//grid-column: span 1;
	}
	//.short-desc{
    //    grid-column: span 4;
	//}

	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
        display: grid;
        grid-template-columns: repeat(8, 1fr);
		border: unset;
		justify-content: unset;
        padding: ${getCssSizeValue(41, BREAKPOINTS.TABLET)} ${getCssSizeValue(33, BREAKPOINTS.TABLET)};
        border: unset;
		&:not(&:last-of-type){
            border-bottom: solid 1px ${({ theme }) => theme.colors.slateGray};
        }
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        //padding: 0 var(--grid-margin-r) 0 0;
        padding: ${getCssSizeValue(41, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        min-height: ${getCssSizeValue(101, BREAKPOINTS.DESKTOP)};
        .name {
            padding-left: ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        }

        transition: background var(--rgr-duration) var(--ease);
		&:hover {
			background: ${({ theme }) => theme.colors.white};
		}
    }
`

export const DEFAULT_DONOR = {
	fullName: 'Donor',
	motto: '',
	story: '',
	age: '',
	image: {
		alt: 'Donor Image',
		focalPoint: {
			x: 0.5,
			y: 0.5
		},
		url: ''
	},
	state: {
		id: '',
		name:''
	}
}

const Donor = ({ data, active, onClick, className='', ...props }) => {
	const WrapperRef = useRef()
	const fullName = data?.fullName || DEFAULT_DONOR.fullName
	const motto = data?.motto || DEFAULT_DONOR.motto
	const age = data?.age || DEFAULT_DONOR.age
	// const textColor = active ? 'white' : 'black'
	const textColor = 'black'
	const isDesktop = useIsDesktop()
	const isMobile = useIsMobile()

	useGSAP(() => {
		if(active){
			gsap.fromTo(WrapperRef.current, {
				'--translate': -100
			},{
				'--translate': 0,
				ease: 'power2.inOut'
			})
		}
	},{
		dependencies: [active]
	})

	return (
		<Wrapper
			ref={WrapperRef}
			onClick={() => onClick(data)}
			className={`${className} ${active ? 'active' : ''} donor`}
			 {...props}
		>
			<Text
				color={textColor}
				className="name typo-borna typo-borna-22 col-mobile-8 col-desktop-2"
			>
				{fullName}
			</Text>
			{(isDesktop && age.length > 0) && (
				<Text
					color={textColor}
					className="age typo-borna typo-borna-20 col-desktop-1"
				>
					{age}
				</Text>
			)}
			{!isMobile && (
				<Text
					color={textColor}
					className="short-desc typo-strawford typo-strawford-23 italic col-mobile-8 col-desktop-5"
				>
					{motto}
				</Text>
			)}
		</Wrapper>
	);
};

export default memo(Donor);
